import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet en forma de bolla mig enterrada, de fins a 5 cm de diàmetre, va sortint del terra i s’obre. La superfície externa està coberta d’uns pèls marrons i l’interna és de color crema clar. Les espores són el·líptiques, de 25-28 x 15-18 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      